<!--
File: Sections.vue
Description: show list of road sections entered in the DB.
-->
<template>
  <div class="md-layout">
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-20">
            <RegionsDropdown :label="$t('label.select_region')" v-model="selected_region" :initial_value="selected_region"
              @input='onRegionChange' />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <DepsDropdown :label="$t('label.select_deu')" v-model="selected_deu" :initial_value="selected_deu"
              :region_id="selected_region" @input='onDeuChange' :disabled='!selected_region' />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <RoadsDropdown :label="$t('label.select_road')" v-model="selected_road" :initial_value="selected_road"
              :region_id="selected_region" @input='onRoadChange' />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <md-input type="search" class="mb-3" clearable style="width: 200px"
                :placeholder="$t('label.search_records')" v-model="searchQuery" />
            </md-field>
          </div>
          <div class="md-layout-item btn-row md-small-size-100">
            <md-button class="md-success" @click="addSection"> {{ $t('buttons.add') }} </md-button>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
          md-mode="indeterminate" />
        <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
          <md-table-empty-state :md-label="$t('label.no_data')"
            :md-description="$t('messages.select_another_criteria')" />
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <!--md-table-cell v-if="!selected_region" style="width:10%" :md-label="$t('road_network.region')" md-sort-by="region">
              {{ item.region }}
            </md-table-cell-->
            <md-table-cell :md-label="$t('road_network.dep')" md-sort-by="deu">
              {{ $t('road_network.dep') + '-' + item.deu }}
            </md-table-cell>
            <md-table-cell v-if="!selected_road" :md-label="$t('road_network.road_key')" md-sort-by="road">
              {{ item.road_key }}
            </md-table-cell>
            <md-table-cell style="min-width: 400px;" :md-label="$t('stdCols.name')" md-sort-by="section_description">
              {{ item.section_description }}
            </md-table-cell>
            <md-table-cell :md-label="$t('inventory.start_distance_m')" md-sort-by="start_distance_m" md-numeric>
              {{ item.start_distance_m | numFormat(0) }}
            </md-table-cell>
            <md-table-cell :md-label="$t('inventory.end_distance_m')" md-sort-by="end_distance_m" md-numeric>
              {{ item.end_distance_m | numFormat(0) }}
            </md-table-cell>

            <md-table-cell :md-label="$t('tables.actions')">
              <div>
                <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                  @click.native="editSection(item)">
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button class="md-just-icon md-success md-simple" :title="$t('buttons.details')"
                  @click="showDetails(item)">
                  <md-icon>preview</md-icon>
                </md-button>
                <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                  @click.stop.prevent="handleDelete(item)">
                  <md-icon>delete</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="footer-table md-table">
          <table>
            <tfoot>
              <tr>
                <th v-for="item in footerTable" :key="item.name" class="md-table-head">
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">{{ item }}</div>
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </md-card-content>
      <md-card-actions md-alignment="space-between">
        <div class>
          <p class="card-category">
            {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
          </p>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label for="pages">{{ $t('label.per_page') }}</label>
            <md-select v-model="pagination.perPage" name="pages">
              <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                {{ item }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
          :per-page="pagination.perPage" :total="total"></pagination>
      </md-card-actions>
    </md-card>

    <div>
      <md-dialog :md-active.sync="showDetailsDlg" :md-click-outside-to-close="false">
        <md-dialog-title>{{ $t('road_network.section_details') }}
          <md-button class='md-simple md-just-icon md-round modal-default-button' @click='showDetailsDlg = false'>
            <md-icon>clear</md-icon>
          </md-button>
        </md-dialog-title>
        <md-dialog-content>
          <label><strong>{{ $t('inventory.carriageway') }}: </strong> {{ section.carriageway }} </label><br>
          <label><b>{{ $t('road_network.category') }}: </b> {{ section.category }} </label><br>
          <label><b>{{ $t('road_network.dep') }}: </b>{{ section.deu }}</label><br>
          <label><b>{{ $t('road_network.pic_id') }}:</b> {{ section.pic_id }} </label><br>
          <label><b>{{ $t('inventory.fclass') }}:</b> {{ section.fclass }}</label><br>
          <label><b>{{ $t('inventory.villages') }}:</b> {{ section.villages }} </label><br>
          <label><b>{{ $t('inventory.aadt') }}:</b> {{ section.aadt }}</label><br>
          <label><b>{{ $t('inventory.social_importance') }}:</b> {{ section.social_importance }} </label><br>
          <label><b>{{ $t('condition.black_spots') }}:</b> {{ section.black_spots }}</label><br>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="md-primary" @click="showDetailsDlg = false">{{ $t('buttons.close') }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
  </div>
</template>
<script>
  import { Pagination } from '@/components'
  import Fuse from 'fuse.js'
  import Swal from 'sweetalert2'
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import DepsDropdown from '../Dropdowns/DepsDropdown.vue'
  import RoadsDropdown from '../Dropdowns/RoadsDropdown.vue'
  import { customSortMixin } from '@/customSortMixin'

  export default {
    mixins: [customSortMixin],
    name: 'sections-list',
    data() {
      return {
        selected_region: null,
        selected_deu: null,
        selected_road: null,
        showDetailsDlg: false,
        section: {},
        oper: null,
        showSpinner: false,
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['road', 'section_description'],
        searchedData: [],
        currentSort: 'road',
        currentSortOrder: 'asc',
        fuseSearch: null,
        footerTable: []
      }
    },
    components: {
      Pagination,
      RegionsDropdown,
      DepsDropdown,
      RoadsDropdown
    },
    mounted() {
      this.showSpinner = true
      this.$store.state.Sections.list = []
      this.$store.dispatch('LOAD_HISTORY').then((history) => {
        if (history.for === 'sections' && history.use) {
          this.selected_region = history.data.selected_region
          this.selected_deu = history.data.selected_deu
          this.selected_road = history.data.selected_road
          this.currentSort = history.data.currentSort
          this.currentSortOrder = history.data.currentSortOrder
          this.pagination.perPage = history.data.perPage
          this.$nextTick(() => {
            this.searchQuery = history.data.searchQuery
            this.pagination.currentPage = history.data.currentPage
          })
          this.$store.dispatch('CLEAR_HISTORY')
          this.reloadData(history.data.searchQuery)
        } else {
          this.selected_region = null
          this.selected_deu = null
          this.selected_road = null
        }
        this.showSpinner = false
      })
    },

    methods: {
      reloadData(searchQuery) {
        this.showSpinner = true
        this.searchQuery = ''
        this.$store.dispatch('LOAD_SECTIONS_LIST', {
          region_id: this.selected_region, road_id: this.selected_road, deu_id: this.selected_deu
        }).then(() => {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.sectionList, { keys: this.propsToSearch, threshold: 0.3 })
          if (searchQuery) this.searchQuery = searchQuery
          this.showSpinner = false
        })
      },
      save_history() {
        const hist = {
          selected_region: this.selected_region,
          selected_deu: this.selected_deu,
          selected_road: this.selected_road,
          searchQuery: this.searchQuery,
          currentSort: this.currentSort,
          currentSortOrder: this.currentSortOrder,
          perPage: this.pagination.perPage,
          currentPage: this.pagination.currentPage
        }
        this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'sections' })
      },
      onRegionChange() {
        this.reloadData(this.searchQuery)
      },
      onDeuChange() {
        this.reloadData(this.searchQuery)
      },
      onRoadChange() {
        this.reloadData(this.searchQuery)
      },
      showDetails(item) {
        this.section = item
        //console.log(item)
        this.showDetailsDlg = true
      },
      editSection(item) {
        this.save_history()
        //console.log(item)
        //this.oper = 'upd'
        this.$router.push(`/inventory_data/sections/upd/${item.section_id}`)
      },
      addSection() {
        this.save_history()
        //this.oper = 'add'
        this.$router.push(`/inventory_data/sections/add`)
      },
      handleDelete(item) {
        const alert = {
          title: this.$t('modal.are_you_sure'),
          html: this.$t('modal.you_want_delete', { name: item.section_description }) + '<br><br>' +
            this.$t('modal.the_operation_cannot_be_canceled'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('modal.yes_delete'),
          cancelButtonText: this.$t('buttons.cancel'),
        }
        this.save_history()
        Swal.fire(alert).then((result) => {
          if (result.value) {
            this.$store.dispatch('DEL_SECTION', item.section_id).then(
              () => {
                this.reloadData(this.searchQuery)
                Swal.fire(this.$t('modal.deleted'), this.$t('modal.you_deleted', { name: item.section_description }), 'success')
              },
              (err) => {
                Swal.fire(this.$t('messages.error'), err, 'error')
              }
            )
            // add here deletion of the geometry - may be in the loop?
            //this.$store.dispatch('DEL_SECTION_GEOMETRY', ...)
          }
        })
      },
    },

    computed: {
      amIAdmin() {
        return this.$store.getters.amIAdmin
      },
      sectionList() {
        return this.customSort(this.$store.state.Sections.list, 'start_distance_m')
      },
      queriedData() {
        const result = !this.searchQuery ? this.sectionList : this.searchedData //? this.searchedData : []
        return result.slice(this.from, this.to)
      },
      to() {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total() {
        return this.searchQuery ? this.searchedData.length : this.sectionList.length
      },
    },

    watch: {
      searchQuery(value) {
        if (this.sectionList.length == 0) {
          this.searchedData = []
          return
        }
        this.searchedData = (value === '' || !this.fuseSearch) ? this.sectionList : this.fuseSearch.search(this.searchQuery)
      }
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>